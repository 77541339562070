import { Controller } from 'stimulus';
import { debugLog } from './debug_helper';

const upKey = 38;
const downKey = 40;
const enterKey = 13;
const navigationKeys = [upKey, downKey, enterKey];

export default class extends Controller {
  static targets = ['result'];

  connect() {
    debugLog('product_search_results_controller: connect');
    this.currentResultIndex = -1;
    this.selectCurrentResult();
  }

  // returns true if user selected a specific result
  navigateResults(event) {
    debugLog('product_search_results_controller: navigateResults');

    if (!navigationKeys.includes(event.keyCode)) return false;

    event.preventDefault();

    switch (event.keyCode) {
      case downKey:
        this.selectNextResult();
        break;
      case upKey:
        this.selectPreviousResult();
        break;
      case enterKey:
        return this.goToSelectedResult();
        break;
    }

    return false;
  }

  selectCurrentResult() {
    debugLog('product_search_results_controller: selectCurrentResult');

    this.resultTargets.forEach((result, index) => {
      result.classList.toggle('has-focus', this.currentResultIndex == index);
    });
  }

  selectNextResult() {
    debugLog('product_search_results_controller: selectNextResult');

    if (this.currentResultIndex < this.resultTargets.length - 1) {
      this.currentResultIndex++;
      this.selectCurrentResult();
    }
  }

  selectPreviousResult() {
    debugLog('product_search_results_controller: selectPreviousResult');

    if (this.currentResultIndex > 0) {
      this.currentResultIndex--;
      this.selectCurrentResult();
    }
  }

  goToSelectedResult() {
    debugLog('product_search_results_controller: goToSelectedResult');

    if (this.currentResultIndex == -1) return false;

    this.resultTargets[this.currentResultIndex].firstElementChild.click();
    return true;
  }

  viewResults() {
    debugLog('product_search_results_controller: viewResults');

    var parentController = getParentController(
      this.application,
      this.resultTarget,
      'product-search'
    );
    if (!parentController) return;
    parentController.viewResults();
  }
}

function getParentController(application, el, id) {
  if (!el || !el.parentElement) {
    debugLog(
      'product_search_results_controller: could not find parent controller: ' +
        id
    );

    return null;
  }

  var controller = application.getControllerForElementAndIdentifier(
    el.parentElement,
    id
  );

  if (controller) {
    return controller;
  } else {
    return getParentController(application, el.parentElement, id);
  }
}
