import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if (tagManagerData) {
      var dataLayer = window.dataLayer || [];
      dataLayer.push(tagManagerData);
    }
  }
}
