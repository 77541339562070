import { Controller } from 'stimulus';
import { debugLog } from './debug_helper';

export default class extends Controller {
  static targets = ['container', 'query', 'results'];
  lastSearchRef = 0;
  pendingSelection = false; // is user busy navigating to result
  isFullWidth = false;

  connect() {
    debugLog('product_search_controller: connect');

    this.lastSearchRef = 0;
    this.queryTarget.value = ''; // clean up old state
    this.pendingSelection = false;
  }

  runSearch(event) {
    debugLog('product_search_controller: runSearch');

    this.applyStyling();

    // we don't search until a user pauses for 300ms
    clearTimeout(this.lastSearchRef);
    this.lastSearchRef = setTimeout(() => {
      debugLog('product_search_controller: debounce event fired');

      this.querySearchEndpoint();
    }, 300);
  }

  removeFocus(event) {
    debugLog('product_search_controller: removeFocus');
    if (this.pendingSelection) return;

    // delay firing this until after navigation events are handled
    window.setTimeout(() => {
      if (this.pendingSelection) return;

      this.queryTarget.value = '';
      this.applyStyling();
      this.removeFullWidth();
    }, 300);
  }

  goFullWidth() {
    this.isFullWidth = true;
    this.containerTarget.classList.remove('no-textbox');
    this.containerTarget.classList.add('full-width');
  }

  removeFullWidth() {
    if (!this.isFullWidth) return;
    this.isFullWidth = false;
    this.containerTarget.classList.add('no-textbox');
    this.containerTarget.classList.remove('full-width');
  }

  async querySearchEndpoint() {
    debugLog('product_search_controller: querySearchEndpoint');

    // don't search on anything less than 2 characters
    if (!this.query || this.query.trim() == '' || this.query.length < 2) {
      this.resultsTarget.innerHTML = '';
      this.previousQuery = '';
      return;
    }

    var query = this.query.trim(); // clean up white space

    if (query == this.previousQuery) return; // no need to search again
    this.previousQuery = query;

    try {
      const response = await fetch(this.url(query));
      var html = await response.text();

      // only show the searched result if the user didn't add anything extra
      if (this.previousQuery === this.query.trim()) {
        this.resultsTarget.innerHTML = html;
      }
    } catch (e) {
      // TODO: add logging to server
      this.resultsTarget.innerHTML = 'Search Error: ' + e;
    }
  }

  viewResults() {
    debugLog('product_search_controller: viewResults');

    if (!this.query || this.query.trim() == '') return;

    this.pendingSelection = true;
    window.location = '/products?scope[for_search]=' + this.query;
  }

  navigateResults(event) {
    debugLog('product_search_controller: navigateResults');

    if (this.resultsController) {
      // TODO: handle navigating through results in other controller using events rather than directly accessing controller
      this.pendingSelection = this.resultsController.navigateResults(event);
    }

    if (!this.pendingSelection && event.keyCode == 13) {
      this.viewResults();
    }
  }

  applyStyling() {
    debugLog('product_search_controller: applyStyling');

    if (!this.query || this.query.trim() == '') {
      this.containerTarget.classList.remove('has-text');
    } else {
      this.containerTarget.classList.add('has-text');
    }
  }

  get resultsController() {
    return this.application.getControllerForElementAndIdentifier(
      this.resultsTarget.firstElementChild,
      'product-search-results'
    );
  }

  url(cleanQuery) {
    const url = new URL(this.baseUrl);
    url.searchParams.append('query', cleanQuery);
    return url;
  }

  get baseUrl() {
    return this.data.get('url');
  }

  get query() {
    return this.queryTarget.value;
  }
}
